import { useRouteError } from "react-router-dom";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout";

const Error = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <Layout>
      <section className="section-error-msg flex f-column w-100">
        <h4>Oops, an error occurred!</h4>
        <p>
          {error.status} {error.statusText || error.message}
        </p>
        <Link className="button" to="/">
          <svg
            className="arrow"
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.5 5.91211H13.84M8.9248 1L13.8375 5.91265L8.9248 10.8253"
              stroke="#adadad"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Back to Homepage
        </Link>
      </section>
    </Layout>
  );
};

export default Error;
