import PropTypes from 'prop-types';

import { Root } from './Button.style';

export const Button = ({ children, ...props }) => {
  return <Root {...props}>{children}</Root>;
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};
