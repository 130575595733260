import { Link } from "react-router-dom";
import logoSrc from "../assets/images/popcorn-sutton-logo-footer.png";
import logoHandwrittenSrc from "../assets/images/popcorn-sutton-logo-handwritten.png";
import facebookSrc from "../assets/vectors/icon-fb.svg";
import FooterSubscribe from "./FooterSubscribe";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container p-l-r-104">
        <img
          className="img m-0-auto flex d-flex"
          src={logoSrc}
          alt="Popcorn Sutton"
        />

        <div className="footer-container__links grid">
          <div className="quick-links flex f-column">
            <a
              href="https://www.facebook.com/PopcornSuttonTN"
              target="_blank"
              rel="noreferrer"
              className="m-b-16"
            >
              <img src={facebookSrc} alt="Facebook" />
            </a>
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms">Terms & Conditions</Link>
            <Link to="/cookies">Cookie Policy</Link>
          </div>
          <div className="copyright m-0-auto flex f-column">
            <img className="handwritten m-b-16" src={logoHandwrittenSrc} />

            <p className="text-center">
              Bottled by Popcorn Sutton Distillery
              <br />
              Newport, TN
              <br />© {new Date().getFullYear()} Popcorn Sutton Distillery
            </p>
          </div>
          <div className="subscribe">
            <FooterSubscribe />
            {/* 
            <a href="#" target="_blank">
              <img src={instagramSrc} alt="Instagram" />
            </a> */}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
