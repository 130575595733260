import React from "react";
import ReactDOM from "react-dom/client";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
// import { ThemeProvider } from "styled-components";
import { RouterProvider } from "react-router-dom";
import ModalProvider from "./context/ModalContext";
import { CookiesProvider } from "react-cookie";
// import "./index.css";

// import GlobalStyle from "./components/GlobalStyle";

import { router } from "./routes";

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    {/* <ThemeProvider theme={{ color: "#000", backgroundColor: "#fff" }}> */}
    {/* <GlobalStyle /> */}
    <GoogleReCaptchaProvider reCaptchaKey="6LfaaKwcAAAAAKA_cozC1lBk7DvgVucS97WeS-MY">
      <ModalProvider>
        <CookiesProvider
          defaultSetOptions={{
            path: "/",
            secure: true,
            domain: (import.meta.env.VITE_FRONTEND_DOMAIN || "localhost").split(
              ":"
            )[0],
          }}
        >
          <RouterProvider router={router} />
        </CookiesProvider>
      </ModalProvider>
    </GoogleReCaptchaProvider>
    {/* </ThemeProvider> */}
  </React.StrictMode>
);
