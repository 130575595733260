import { Link } from "react-router-dom";
import useScript from "../../hooks/useScript";

import Layout from "../../components/Layout";

const Termly = ({ id, title }) => {
  useScript("https://app.termly.io/embed-policy.min.js");

  return (
    <Layout>
      <section className="section-terms flex f-column p-tb-140 p-lr-164 w-100">
        <h1>{title}</h1>
        {/* <!-- Generated Content --> */}
        <div
          name="termly-embed"
          data-id={id}
          data-type="iframe"
          className="w-100 legal-p"
        ></div>

        {/* <!-- Nav Button  --> */}
        <Link className="button" to="/">
          <svg
            className="arrow"
            width="15"
            height="12"
            viewBox="0 0 15 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.5 5.91211H13.84M8.9248 1L13.8375 5.91265L8.9248 10.8253"
              stroke="#adadad"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Back to Homepage
        </Link>
      </section>
    </Layout>
  );
};

export default Termly;
