import { useState } from 'react';

import {
  Root,
  Desc,
  Title,
  Location,
  Details,
  Actions,
} from './MapListItem.style';
import { Button } from '../Button/Button';

export const MapListItem = ({ location, onMapView }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const directionsUrl = `https://maps.google.com/?q=${encodeURIComponent(
    `${location.street},${location.city},${location.state}`
  )}`;

  return (
    <Root>
      <Desc type="button" onClick={() => onMapView(location)}>
        <Title>
          <span>{location.dba}</span>

          {/* {isExpanded ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="1em"
              height="1em"
            >
              <path
                fill="currentColor"
                d="m3.969 15.22 7.5-7.5a.742.742 0 0 1 .53-.22.747.747 0 0 1 .531.22l7.5 7.5a.747.747 0 0 1 0 1.061.75.75 0 0 1-1.061 0l-6.97-6.97-6.969 6.97a.753.753 0 0 1-.818.163.742.742 0 0 1-.406-.406.75.75 0 0 1 .163-.818Z"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="1em"
              height="1em"
            >
              <path
                fill="currentColor"
                d="m20.03 8.781-7.5 7.5a.742.742 0 0 1-.53.22.747.747 0 0 1-.531-.22l-7.5-7.5a.747.747 0 0 1 0-1.061.75.75 0 0 1 1.061 0L12 14.69l6.969-6.97a.753.753 0 0 1 .818-.163.742.742 0 0 1 .406.406.75.75 0 0 1-.163.818Z"
              />
            </svg>
          )} */}
        </Title>

        <Location>
          <span>
            {location.street}, {location.city}, {location.state} {location.zip}
          </span>
          <span>{parseFloat(location.distance).toFixed(1)} Miles</span>
        </Location>
      </Desc>

      {isExpanded && (
        <Details>
          <Actions>
            <Button
              className="--hideOnDesktop"
              type="button"
              onClick={() => onMapView(location)}
              style={{ height: 32, padding: '0 12px' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={16}
                height={16}
              >
                <path
                  fill="currentColor"
                  d="M21.461 4.658a.754.754 0 0 0-.643-.136l-5.731 1.433-5.751-2.876a.755.755 0 0 0-.518-.057l-6 1.5a.752.752 0 0 0-.568.728v13.5a.752.752 0 0 0 .932.728l5.731-1.433 5.751 2.876a.757.757 0 0 0 .518.057l6-1.5a.752.752 0 0 0 .568-.728V5.25a.749.749 0 0 0-.289-.592ZM9.75 4.963l4.5 2.25v11.824l-4.5-2.25V4.963Zm-6 .873 4.5-1.125v11.953l-4.5 1.125V5.836Zm16.5 12.328-4.5 1.125V7.336l4.5-1.125v11.953Z"
                />
              </svg>
              <span>View Map</span>
            </Button>

            <Button
              as="a"
              href={`tel:${location.phoneFormatted}`}
              style={{ height: 32, padding: '0 12px' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                width={16}
                height={16}
              >
                <path
                  fill="currentColor"
                  d="m17.06 12.692-3.68-1.649-.011-.005a1.246 1.246 0 0 0-1.243.153l-1.902 1.621c-1.205-.585-2.448-1.819-3.034-3.008l1.624-1.931a1.247 1.247 0 0 0 .148-1.236v-.01L7.308 2.941a1.253 1.253 0 0 0-1.299-.744 4.394 4.394 0 0 0-3.822 4.365c0 6.203 5.047 11.25 11.25 11.25a4.395 4.395 0 0 0 4.366-3.822 1.247 1.247 0 0 0-.743-1.298Zm-3.623 3.87a10.011 10.011 0 0 1-10-10c-.003-.762.272-1.5.774-2.075a3.138 3.138 0 0 1 1.951-1.05.017.017 0 0 0 0 .01l1.64 3.672-1.615 1.932a1.236 1.236 0 0 0-.244.664c-.014.215.028.429.122.623.707 1.448 2.166 2.895 3.629 3.602a1.247 1.247 0 0 0 1.289-.132l1.899-1.621 3.672 1.645h.008a3.142 3.142 0 0 1-3.125 2.73Z"
                />
              </svg>
              <span>Call</span>
            </Button>

            <Button
              as="a"
              href={directionsUrl}
              target="_blank"
              style={{ height: 32, padding: '0 12px' }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                width={16}
                height={16}
              >
                <path
                  fill="currentColor"
                  d="M17.291 7.045 3.547 1.95l-.012-.004a1.249 1.249 0 0 0-1.59 1.586l.004.013 5.098 13.743a1.233 1.233 0 0 0 1.178.834h.023a1.233 1.233 0 0 0 1.172-.882l.005-.016 1.706-6.094 6.094-1.706.015-.004a1.251 1.251 0 0 0 .049-2.374l.002-.001Zm-6.835 2.975a.626.626 0 0 0-.433.433l-1.797 6.419-.004-.013L3.125 3.122l13.736 5.095.012.005-6.417 1.798Z"
                />
              </svg>
              <span>Get directions</span>
            </Button>
          </Actions>
        </Details>
      )}
    </Root>
  );
};
