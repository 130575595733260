import styled, { css, keyframes } from "styled-components";
// import modalBorder from "../../assets/images/modal-border.png";
// import AgeSrc from "../../images/age-confirm.png";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const Root = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(14, 14, 14, 0.8);
  animation-duration: 75ms;
  animation-timing-function: ease-out;
  outline: 0;
  z-index: 7000;
  backdrop-filter: blur(1px);

  @media (min-width: 1024px) and (max-height: 534px) {
    padding: 48px 0;
    overflow-y: visible;
  }

  @media (min-width: 1024px) and (min-height: 535px) {
    align-items: center;
  }

  ${(p) =>
    p.$isShow &&
    css`
      animation-name: ${fadeIn};
    `}

  ${(p) =>
    p.$isHide &&
    css`
      animation-name: ${fadeOut};
    `}
`;

export const Dialog = styled.div`
  @media (max-width: 1023px) {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1024px) {
    width: 648px;
    min-height: 500px;
    margin: 0 auto;
    overflow: hidden;
    pointer-events: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;

  outline: 0;
  pointer-events: all;

  ${"" /* background-image: url("${modalBorder});
  background-size: cover;
  background-position: top;
background-repeat: no-repeat; */}

  @media (max-width: 1023px) {
    justify-content: center;
  }

  @media (min-width: 1024px) {
    background-clip: padding-box;
    ${"" /* background-image: url(${AgeSrc}); */}
    background-repeat: no-repeat;
    background-size: 621px 461px;
    background-position: center;
  }

  @media (max-width: 767px) {
    padding: 0 24px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding: 56px 104px;
  overflow-y: hidden;

  @media (max-width: 1023px) {
    margin-top: 0;
  }

  @media (min-width: 1024px) {
    justify-content: center;
  }

  @media (min-height: 535px) {
  }

  @media (max-width: 767px) {
    padding: 56px 0;
  }
`;

export const Logo = styled.img`
  display: none;
`;

export const Border = styled.img`
  position: absolute;
  display: block;
  left: 0;
  top: 1px;
  width: 100%;
`;

export const Text = styled.p`
  margin: 0 auto;
`;

export const Label = styled.span`
  margin: 0 auto;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 40px;
  width: 100%;

  @media (max-width: 1023px) {
    padding: 0;
    margin-top: 24px;
  }
`;

export const Select = styled.select`
  height: 40px;
  padding: 8px;
  color: #adadad;
  font-family: "Esteban", serif;
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #adadad;

  @media (max-width: 1023px) {
    display: block;
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 48px;
  margin-top: 40px;
  font-family: "Evanston Alehouse 1826 Light", serif;
  font-size: 18px;
  text-align: center;
  background-color: #c26b38;
  color: #fff;
`;
