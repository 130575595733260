import styled from 'styled-components';

export const Root = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  width: fit-content;
  height: 40px;
  padding: 0 16px;
  color: #adadad;
  background-color: transparent;
  font-family: inherit;
  font-size: 14px;
  letter-spacing: 0.84px;
  text-align: center;
  text-transform: capitalize;
  border: 1px solid #adadad;
  transition: all 0.3s;

  &:not(:disabled):hover {
    color: #fff;
    border: 1px solid #fff;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
`;
