import { InfoWindowF } from '@react-google-maps/api';

import {
  MarkerPopup,
  MarkerPopupTitle,
  MarkerPopupContainer,
  MarkerPopupAddress,
  MarkerPopupActions,
  MarkerPopupAction,
} from './Find.style';

export const InfoWindow = ({ selectedMarker, onClose }) => {
  return (
    <InfoWindowF
      position={{
        lat: parseFloat(selectedMarker.lat),
        lng: parseFloat(selectedMarker.long),
      }}
      options={{ pixelOffset: new window.google.maps.Size(0, -48) }}
      onCloseClick={onClose}
    >
      <MarkerPopup>
        <MarkerPopupContainer>
          <MarkerPopupTitle>{selectedMarker.dba}</MarkerPopupTitle>
          <MarkerPopupAddress>
            <div>{selectedMarker.street}</div>
            <div>
              {selectedMarker.state} {selectedMarker.zip}
            </div>
          </MarkerPopupAddress>
          <MarkerPopupActions>
            <MarkerPopupAction href={`tel:${selectedMarker.phone}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width={16}
                height={16}
              >
                <path
                  fill="currentColor"
                  d="m13.648 10.154-2.944-1.32-.009-.003a.993.993 0 0 0-.995.122L8.179 10.25c-.964-.468-1.959-1.456-2.427-2.407l1.299-1.544.036-.048a.998.998 0 0 0 .082-.942v-.007L5.846 2.353a1.003 1.003 0 0 0-1.039-.595A3.513 3.513 0 0 0 1.75 5.25c0 4.963 4.037 9 9 9a3.512 3.512 0 0 0 3.492-3.057 1.004 1.004 0 0 0-.124-.619 1.004 1.004 0 0 0-.47-.42ZM10.75 13.25a8.009 8.009 0 0 1-8-8 2.51 2.51 0 0 1 2.179-2.5v.008l1.313 2.937L4.95 7.241a1.005 1.005 0 0 0-.098 1.03c.566 1.158 1.733 2.316 2.903 2.882a1.009 1.009 0 0 0 1.031-.107l1.519-1.296 2.938 1.316h.007a2.514 2.514 0 0 1-2.5 2.184Z"
                />
              </svg>
              <span>{selectedMarker.phoneFormatted}</span>
            </MarkerPopupAction>
            <MarkerPopupAction
              href={`https://maps.google.com/?q=${encodeURIComponent(
                `${selectedMarker.street},${selectedMarker.city},${selectedMarker.state}`
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
                width={16}
                height={16}
              >
                <path
                  fill="currentColor"
                  d="M13.833 5.635 2.838 1.56l-.01-.004a1.01 1.01 0 0 0-1.032.238 1 1 0 0 0-.24 1.031l.003.01L5.638 13.83a.988.988 0 0 0 .942.667h.018a.986.986 0 0 0 .938-.705l.004-.013 1.365-4.875 4.875-1.365.012-.004a.996.996 0 0 0 .706-.935 1.003 1.003 0 0 0-.667-.964l.002-.001ZM8.365 8.016a.497.497 0 0 0-.347.346l-1.437 5.135-.004-.01L2.5 2.497l10.988 4.076.01.004-5.133 1.439Z"
                />
              </svg>
              <span>Directions</span>
            </MarkerPopupAction>
          </MarkerPopupActions>
        </MarkerPopupContainer>
      </MarkerPopup>
    </InfoWindowF>
  );
};
