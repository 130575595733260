import React, { useState, useEffect } from "react";
import { Portal } from "react-portal";
import { useCookies } from "react-cookie";
import {
  Root,
  Dialog,
  Content,
  Body,
  Border,
  Text,
  Logo,
  Label,
  Form,
  Select,
  Submit,
} from "./AgeConfirm.style";

import logoSrc from "../../assets/images/popcorn-sutton-logo-footer.png";
import modalBorder from "../../assets/images/modal-border.png";
import useLockBodyScroll from "../../hooks/useLockBodyScroll";

const AgeConfirm = () => {
  // useLockBodyScroll();
  const [cookies, setCookie] = useCookies(["ageConfirm"]);
  const [isShow, setIsShow] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    if (cookies.ageConfirm !== "confirmed") setIsShow(true);
  }, [cookies.ageConfirm]);

  const months = [
    { value: "January" },
    { value: "February" },
    { value: "March" },
    { value: "April" },
    { value: "May" },
    { value: "June" },
    { value: "July" },
    { value: "August" },
    { value: "September" },
    { value: "October" },
    { value: "November" },
    { value: "December" },
  ];

  const years = () => {
    const date = new Date();
    let options = [];
    for (let i = 1930; i <= date.getFullYear(); i++) {
      options.push(i);
    }
    return options
      .map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))
      .slice()
      .reverse();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFormValues = {
      ...formValues,
      [name]: value,
    };
    console.info({ newFormValues });
    setFormValues(newFormValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.info({ formValues });
    if (
      ["", null, undefined].includes(formValues?.year) ||
      ["", null, undefined].includes(formValues?.month) ||
      ["", null, undefined].includes(formValues?.day)
    ) {
      return setIsInvalid(true);
    }
    setIsInvalid(false);
    const age = Math.floor(
      (new Date() -
        new Date(
          `${formValues.year}-${formValues.month}-${formValues.day}`
        ).getTime()) /
        3.15576e10
    );
    if (age >= 21) {
      setCookie("ageConfirm", "confirmed", {
        maxAge: 60 * 60 * 24 * 365,
      });
      setTimeout(() => {
        setIsShow(false);
      }, 75);
    } else {
      // alert(age + " -- " + `${formValues.year}-${formValues.month}-${formValues.day}`)
      window.location.href = "https://www.responsibility.org/";
    }
  };

  return isShow ? (
    <Portal>
      <Root
        tabIndex={-1}
        role="dialog"
        aria-modal
        $isShow={isShow}
        $isHide={!isShow}
      >
        <Dialog>
          <Content>
            <Body>
              <Logo src={logoSrc} alt="Popcorn Sutton"></Logo>
              <Border src={modalBorder} alt="border"></Border>
              <h1 className="m-b-24">Are you over 21?</h1>
              <p className="text-center">
                Can we some see some ID please?
                <br />
                It’s part of our commitment to responsible drinking.
              </p>
              <Form onSubmit={handleSubmit}>
                {isInvalid && (
                  <p
                    style={{
                      color: "#63656a",
                      position: "absolute",
                      top: "-24px",
                    }}
                  >
                    Invalid birthdate.
                  </p>
                )}
                <div style={{ width: "100%" }}>
                  <Select
                    name="month"
                    value={formValues.month}
                    onChange={handleChange}
                  >
                    <option value="">Month</option>
                    {months.map((month, idx) => (
                      <option
                        key={month.value}
                        value={`${idx + 1}`.padStart(2, "0")}
                      >
                        {month.value}
                      </option>
                    ))}
                  </Select>
                  <Select
                    name="day"
                    value={formValues.day}
                    onChange={handleChange}
                  >
                    <option value="">Day</option>
                    {Array.from({ length: 31 }, (_, i) => (
                      <option key={i + 1} value={`${i + 1}`.padStart(2, "0")}>
                        {i + 1}
                      </option>
                    ))}
                  </Select>
                  <Select
                    name="year"
                    value={formValues.year}
                    onChange={handleChange}
                  >
                    <option value="">Year</option>
                    {years()}
                  </Select>
                </div>

                <Submit type="submit">Come In</Submit>
              </Form>
            </Body>
          </Content>
        </Dialog>
      </Root>
    </Portal>
  ) : null;
};

export default AgeConfirm;
