import styled from 'styled-components';

export const Root = styled.li`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    padding-top: 24px;
  }

  &:not(:last-child) {
    padding-bottom: 24px;
    border-bottom: 1px solid #fff;
  }
`;

export const Desc = styled.button`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  background-color: transparent;
  text-align: left;
  border: 0 none;
  cursor: pointer;
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 24px;
  color: #fff;
  font-family: 'Evanston Alehouse 1826 Light';
  font-size: 22px;
  font-weight: 300;
  text-transform: uppercase;
`;

export const Location = styled.div`
  display: flex;
  flex-direction: column;
  color: #adadad;
  font-size: 13px;
  letter-spacing: 0.13px;
  line-height: 20px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding-top: 32px;
  padding-bottom: 16px;
`;

export const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  .--hideOnDesktop {
    @media (min-width: 1024px) {
      display: none;
    }
  }
`;
