import { useState, useEffect, useRef, useCallback } from "react";
import { Portal } from "react-portal";
import { useCookies } from "react-cookie";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import {
  Root,
  Dialog,
  Content,
  Body,
  Logo,
  Border,
  // Text,
  // Label,
  Form,
  // Select,
  Submit,
} from "./ModalSubscribe.style";

import logoSrc from "../../assets/images/popcorn-sutton-logo-footer.png";
import modalBorder from "../../assets/images/modal-border.png";
// import useLockBodyScroll from "../../hooks/useLockBodyScroll";
// import { set } from "react-hook-form";
import closeSrc from "../../assets/vectors/close-icon.svg";

const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ModalSubscribe = () => {
  const modalRef = useRef(null);
  const [cookies, setCookie, removeCookie] = useCookies([
    "ageConfirm",
    "subscribeModal",
    "promoModal",
  ]);
  // useLockBodyScroll();
  // const [formValues, setFormValues] = useState({ email: "" });
  // const [isInvalid, setIsInvalid] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [emailAddress, setEmailAddress] = useState("");
  const [formStatus, setFormStatus] = useState("");

  // modal trigger logic.  this component renders everytime.  returned jsx (or null) is what determines if it shows or not
  useEffect(() => {
    if (
      cookies.subscribeModal === "dismissed" ||
      cookies.ageConfirm !== "confirmed"
    ) {
      return setIsOpen(false);
    }
    const timeoutID = setTimeout(() => setIsOpen(true), 5000);

    return () => {
      clearTimeout(timeoutID);
    };
  }, [cookies.ageConfirm, cookies.subscribeModal]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailAddress == null || emailAddress === "") return;
    if (!emailPattern.test(emailAddress)) {
      setFormStatus("Invalid Email.");
      return;
    }
    if (!executeRecaptcha) {
      setFormStatus("Invalid reCAPTCHA. Please try again.");
      return;
    }
    const captchaToken = await executeRecaptcha("subscribe");

    try {
      setFormStatus("Submitting...");
      const response = await fetch(
        `https://api.olesmoky.com/subscribe-popcornsutton`,
        {
          method: "POST",
          body: JSON.stringify({ emailAddress, captchaToken }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // const responseJson = await response.json();
      // console.info({ responseJson });
      setEmailAddress("");
      setFormStatus("Success signing up!");
    } catch (err) {
      console.error({ err });
      const errorJson = err.responseJSON;
      if (
        ["INVALID_CAPTCHA", "INVALID_CAPTCHA_SCORE"].includes(errorJson.type)
      ) {
        setFormStatus("Invalid captcha. Please try again.");
      } else {
        setFormStatus("An error occurred. Please try again.");
      }
    }
  };

  const handleFocus = () => {
    const recaptchaEl = document.getElementsByClassName("grecaptcha-badge");
    if (recaptchaEl?.[0]?.style != null) {
      recaptchaEl[0].style.visibility = "visible";
    }
  };

  const dismissModal = useCallback(() => {
    setCookie("subscribeModal", "dismissed", {
      maxAge: 60 * 60 * 24 * 7,
    });
    setCookie("promoModal", "dismissed", {
      maxAge: 10,
    });
    setTimeout(() => {
      removeCookie("promoModal");
    }, 10 * 1000);
  }, []);

  // onclick outside logic
  useEffect(() => {
    if (!modalRef.current) return;
    const eventNames = [`click`, `touchstart`];
    const eventHandler = (evt) => {
      if (modalRef.current?.contains(evt.target)) return;
      dismissModal();
    };
    eventNames.forEach((eventName) => {
      document.addEventListener(eventName, eventHandler);
    });
    return () => {
      eventNames.forEach((eventName) => {
        document.removeEventListener(eventName, eventHandler);
      });
    };
  }, [dismissModal]);

  return isOpen ? (
    <Portal>
      <Root
        tabIndex={-1}
        role="dialog"
        aria-modal
        $isShow={isOpen}
        $isHide={!isOpen}
      >
        <Dialog ref={modalRef} style={{ zIndex: 999999 }}>
          <Content>
            <Body>
              <button
                className="close-button"
                onClick={dismissModal}
                style={{ cursor: "pointer" }}
              >
                <img alt="close" src={closeSrc} className="close-icon" />
              </button>
              <Logo src={logoSrc} alt="Popcorn Sutton"></Logo>
              <Border src={modalBorder} alt="border"></Border>
              <h1 className="m-b-24 text-center">
                Sign in to receive our latest news
              </h1>
              <p className="text-center">
                Stay updated with our latest news and exclusive offers! Sign up
                for our email subscription to receive exciting updates directly
                to your inbox. Don't miss out – join now!
              </p>
              <Form onSubmit={handleSubmit}>
                {/* {isInvalid && (
                  <p
                    style={{
                      color: "#63656a",
                      position: "absolute",
                      top: "-24px",
                    }}
                  >
                    Invalid email address.
                  </p>
                )} */}
                {formStatus && (
                  <p
                    style={{
                      color: "#db8f5e",
                      position: "absolute",
                      bottom: "-40px",
                    }}
                  >
                    {formStatus}
                  </p>
                )}
                <div style={{ width: "100%" }}>
                  <input
                    type="text"
                    className="email-input"
                    placeholder="Enter your email"
                    name="email"
                    value={emailAddress}
                    onChange={(e) => setEmailAddress(e.target.value)}
                    onFocus={handleFocus}
                  />
                </div>

                <Submit type="submit">Sign Up</Submit>
              </Form>
            </Body>
          </Content>
        </Dialog>
      </Root>
    </Portal>
  ) : null;
};

export default ModalSubscribe;
