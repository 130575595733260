import {
  Address,
  Desc,
  Distilleries,
  Distillery,
  DistilleryImage,
  DistilleryText,
  Root,
  TopContainer,
} from './DistilleryLocations.style';

import hollerSrc from '../../assets/images/distillery-holler.jpg';
import barnSrc from '../../assets/images/distillery-barn.jpg';
import barrelhouseSrc from '../../assets/images/distillery-barrelhouse.jpg';
import nashvilleSrc from '../../assets/images/distillery-nashville.jpg';

export const DistilleryLocations = () => {
  return (
    <Root>
      <TopContainer>
        <h1>Available to Purchase at Ole Smoky Distillery Locations</h1>
        <Desc>
          Discover Ole Smoky Distilleries in the heart of the Great Smoky
          Mountains and also in the state capital city of Nashville. Come see
          us, and you'll find Popcorn Sutton products available for purchase.
        </Desc>
      </TopContainer>

      <Distilleries>
        <Distillery>
          <DistilleryImage src={hollerSrc} alt="The Holler" />
          <DistilleryText>
            <h2>The Holler</h2>
            <Address>
              903 Parkway #128,
              <br />
              Gatlinburg, TN 37738
            </Address>
          </DistilleryText>
        </Distillery>

        <Distillery>
          <DistilleryImage src={barnSrc} alt="The Barn" />
          <DistilleryText>
            <h2>The Barn</h2>
            <Address>
              131 The Island Dr #9116,
              <br />
              Pigeon Forge, TN 37863
            </Address>
          </DistilleryText>
        </Distillery>

        <Distillery>
          <DistilleryImage src={barrelhouseSrc} alt="The Barrelhouse" />
          <DistilleryText>
            <h2>The Barrelhouse</h2>
            <Address>
              650 Parkway,
              <br />
              Gatlinburg, TN 37738
            </Address>
          </DistilleryText>
        </Distillery>

        <Distillery>
          <DistilleryImage src={nashvilleSrc} alt="6th & Peabody" />
          <DistilleryText>
            <h2>6th & Peabody</h2>
            <Address>
              423 6th Ave S;
              <br />
              Nashville, TN 37203
            </Address>
          </DistilleryText>
        </Distillery>
      </Distilleries>
    </Root>
  );
};
