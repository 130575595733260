// import React, { useContext } from "react";
import { Link } from "react-router-dom";
// import { useCookies } from "react-cookie";
import logoSrc from "../assets/images/popcorn-sutton-logo-header-2.png";
import logoHandwrittenSrc from "../assets/images/popcorn-sutton-logo-handwritten.png";
// import ModalPromo from "./ModalPromo/ModalPromo";

const Header = () => {
  // const [cookies, setCookie, removeCookie] = useCookies(["promoModal"]);

  return (
    <header className="w-100">
      {/* <div className="notification-bar flex w-100 text-center">
        <button
          style={{ cursor: "pointer" }}
          onClick={() => {
            console.info("clicked");
            removeCookie("promoModal");
          }}
        >
          <h3>Launch Event 12/8: Click Here to learn more</h3>
        </button>
      </div> */}
      <div className="header-container w-100 m-0-auto h-100 flex f-row">
        <Link className="m-0-auto" to="/">
          <img
            className="logo block m-0-auto"
            src={logoSrc}
            alt="Popcorn Sutton Logo"
          />
        </Link>
      </div>
      <div className="fixed-header w-100 flex f-row">
        <img alt="Popcorn Sutton" src={logoHandwrittenSrc} />
      </div>
    </header>
  );
};

export default Header;
