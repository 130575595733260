import styled from 'styled-components';

export const Root = styled.label`
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  column-gap: 8px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const Mark = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  color: transparent;
  background-color: transparent;
  border: 1px solid #adadad;
`;

export const Input = styled.input`
  position: absolute;
  inset: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit;

  &:checked + ${Mark} {
    color: #fff;
    background-color: #f78545;
    border: 1px solid #f78545;
  }
`;
