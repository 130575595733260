// import logoSrc from "../../assets/henkel-logo.svg";
import Layout from '../../components/Layout';
import Map from '../../components/Map/Map';
import bronzeBottleSrc from '../../assets/images/bottle-bourbon-whiskey.png';
import whiteBottleSrc from '../../assets/images/bottle-likker.png';
import AgeConfirm from '../../components/AgeConfirm/AgeConfirm';
import ModalSubscribe from '../../components/ModalSubscribe/ModalSubscribe';
import ModalPromo from '../../components/ModalPromo/ModalPromo';
import { MerchSlider } from '../../components/MerchSlider/MerchSlider';
import { Find } from '../../components/Find/Find';
import { DistilleryLocations } from '../../components/DistilleryLocations/DistilleryLocations';

const Home = () => {
  return (
    <Layout showModals={true}>
      <div id="switch-order" className="h-100 w-100">
        {/* SECTION PRODUCTS --> */}
        <section className="section-product p-lr-164">
          <div className="section-product__container grid">
            {/* Product Block 1 --> */}
            <div className="section-product__container--product product-1 block h-100 flex f-row">
              <img
                className="block bottle"
                src={bronzeBottleSrc}
                alt="Bourbon Whiskey"
              />

              <div className="text-block m-auto">
                <h1 className="m-b-24">Bourbon Whiskey</h1>
                <div>
                  <h3>A Blend of Straight Bourbon Whiskeys</h3>
                  <p>
                    Aged bourbon barrels from the hills of North Carolina and
                    Tennessee come together to create this smooth 100 proof
                    blend of straight bourbon whiskeys, with notes of caramel,
                    leather, citrus and char.
                  </p>
                </div>
              </div>
            </div>

            {/* Product Block 2 --> */}
            <div className="section-product__container--product product-2 block h-100 flex f-row">
              <img className="block bottle" src={whiteBottleSrc} alt="Likker" />

              <div className="text-block m-auto">
                <h1 className="m-b-24">Likker</h1>
                <div>
                  <h3>Inspired by the legend himself</h3>
                  <p>
                    A mash of cane and grain is masterfully fermented and
                    distilled to create an unforgettable taste. The memory of
                    the first sip will stay with you, as will every sip after
                    that. Enjoy Popcorn's 100 proof likker.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* SECTION INTRO 1 --> */}
        <section className="section-intro p-tb-140">
          <div className="section-intro__container w-600 m-0-auto">
            <h2 className="text-center m-b-32">
              Popcorn Sutton was{' '}
              <span>moonshine whiskey's greatest ambassador</span>
              <br /> and its greatest craftsman.
            </h2>
            <p className="text-center">
              His spirit was born from deep holler; from wood, steel, fire,
              water, shotgun, and Appalachian mist. His story is alive and well
              and it speaks for all who live without apology or regret. Popcorn
              Sutton is a legend and his distinguished, genuine, whiskey and
              likker are here. Many claim they possess a jar of his last batch
              but few do. Now all of us can enjoy the most hidden of treasures.
              May his last batch live forever.
            </p>
          </div>
        </section>
      </div>

      {/* SECTION POPCORN --> */}
      <section className="section-popcorn bg-img-default p-lr-164">
        <div className="section-popcorn__container h-100  ">
          <div className="section-popcorn__container--frame w-100 h-100 js--wp-1">
            <div className="text-block h-100 text-center flex f-column m-0-auto">
              <h2 className="m-b-32">
                Marvin "Popcorn" Sutton is known in the hills of Appalachia and
                around the world as a <span>moonshine whiskey legend</span>
              </h2>
              <p>
                Cocke County, Tennessee and Maggie Valley, North Carolina are
                both places Popcorn called his home. Dedication to keeping the
                mountain culture and Appalachian history alive was very
                important to him. The richness of the culture could be tasted in
                the high quality of his craftsmanship. Anyone lucky enough to
                possess a jar of spirits crafted by Popcorn Sutton can tell you
                that his passion for truth and freedom can be tasted in every
                sip. Located in Newport, Tennessee, Popcorn Sutton Distillery
                distills more than fine whiskey and liquor -- they distill
                stories that will keep Popcorn's spirit alive and well for all
                to enjoy.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section-intro-2 p-tb-140">
        <div className="section-intro-2__container w-600 m-0-auto">
          <h2 className="text-center">
            <span>Pam Sutton</span>, widow of the legendary moonshiner and folk
            hero Popcorn Sutton, is joining forces with Sevier County native and
            Ole Smoky Founder, <span>Joe Baker</span>, to preserve Popcorn’s
            legacy and bring his original recipes to market.
          </h2>
        </div>
      </section>

      {/* SECTION PAM & JOE --> */}
      <section className="section-pamsutton bg-img-default p-lr-164">
        <div className="section-pamsutton__container h-100">
          <div className="section-pamsutton__container--frame w-100 h-100 js--wp-2">
            <div className="text-block h-100 text-center flex f-column m-0-auto">
              <h2 className="m-b-32">joe baker & pam sutton</h2>
              <p>
                Joe Baker & Pam Sutton, Popcorn's widow, have joined forces and
                are working together to honor her late husband's legacy and
                craft. Popcorn is a true local legend. Joe and Pam are
                protecting, honoring, and carrying on that legacy. No one knew
                Popcorn better than Pam, a fine moonshiner in her own right.
                They're proud to share with you the rich Appalachian heritage
                that Popcorn represents, and his craft and his brands ain't
                going nowhere -- they will stay true to their hometown roots.
                Popcorn made his legendary likker right here in this area and
                here it will stay.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* <Map /> */}
      <Find />

      <DistilleryLocations />

      <MerchSlider />

      <AgeConfirm
      // isShow={isShowAgeConfirm}
      // isHide={isRunAnimation}
      // onConfirm={handleHideAgeConfirm}
      />
      <ModalSubscribe />
      {/* <ModalPromo /> */}
    </Layout>
  );
};

export default Home;
