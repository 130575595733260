import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1023px) {
    row-gap: 56px;
    padding: 80px 24px;
  }

  @media (min-width: 1024px) {
    row-gap: 48px;
    padding: 104px 24px;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1023px) {
    row-gap: 16px;
  }

  @media (min-width: 1024px) {
    row-gap: 24px;
    max-width: 600px;
  }
`;

export const Desc = styled.div`
  color: #adadad;
  font-family: 'Esteban';
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.13px;
  text-align: center;

  @media (min-width: 1024px) {
    width: 450px;
  }
`;

export const Distilleries = styled.div`
  @media (max-width: 1023px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
    row-gap: 32px;
  }

  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 864px;
  }
`;

export const Distillery = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
  padding: 10px;
`;

export const DistilleryImage = styled.img`
  width: 56px;
  height: 56px;
  object-fit: cover;
`;

export const DistilleryText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  text-align: center;
`;

export const Address = styled.div`
  color: #adadad;
  font-family: 'Esteban';
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.13px;
`;
