import { createBrowserRouter } from "react-router-dom";

import Home from "./Home/Home";
import Termly from "./Termly/Termly";
import Error from "./Error/Error";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error />,
  },
  {
    path: "/terms",
    element: (
      <Termly
        id="bf3c7beb-7ae4-4ba0-a809-1324e639e848"
        title="Terms & Conditions"
      />
    ),
    errorElement: <Error />,
  },
  {
    path: "/privacy",
    element: (
      <Termly
        id="ae7af351-16ad-4f73-bcc0-8c689e1d2637"
        title="Privacy Policy"
      />
    ),
    errorElement: <Error />,
  },
  {
    path: "/cookies",
    element: (
      <Termly id="6b1c55c1-c8d6-444b-b9cd-2b99fb48f60d" title="Cookie Policy" />
    ),
    errorElement: <Error />,
  },
]);

export { router };
