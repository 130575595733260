import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import {
  Root,
  Slide,
  SliderContainer,
  ImageContainer,
  SlideText,
  SlideTitle,
} from './MerchSlider.style';

import { Button } from '../Button/Button';

const sliderSettings = {
  arrows: true,
  dots: false,
  infinite: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const MerchSlider = () => {
  return (
    <Root>
      <h1>Shop our merch</h1>

      <SliderContainer>
        <Slider {...sliderSettings}>
          <Slide>
            <ImageContainer
              as="a"
              href="https://olesmoky.com/collections/mens-apparel/products/popcorn-flag-tee"
              target="_blank"
            >
              <img
                src="https://olesmoky.com/cdn/shop/files/MNSMPOPCORNFLAGTEE_600x600.png?v=1706723616"
                alt="POPCORN FLAG TEE"
              />
            </ImageContainer>
            <SlideText>
              <SlideTitle>POPCORN FLAG TEE</SlideTitle>
              <Button
                as="a"
                href="https://olesmoky.com/collections/mens-apparel/products/popcorn-flag-tee"
                target="_blank"
              >
                Shop
              </Button>
            </SlideText>
          </Slide>

          <Slide>
            <ImageContainer
              as="a"
              href="https://olesmoky.com/collections/mens-apparel/products/popcorn-sutton-outlaw-tee"
              target="_blank"
            >
              <img
                src="https://olesmoky.com/cdn/shop/files/MNSMPOPCORNSUTTONOUTLAWTEE_600x600.png?v=1706723892"
                alt="POPCORN SUTTON OUTLAW TEE"
              />
            </ImageContainer>
            <SlideText>
              <SlideTitle>POPCORN SUTTON OUTLAW TEE</SlideTitle>
              <Button
                as="a"
                href="https://olesmoky.com/collections/mens-apparel/products/popcorn-sutton-outlaw-tee"
                target="_blank"
              >
                Shop
              </Button>
            </SlideText>
          </Slide>

          <Slide>
            <ImageContainer
              as="a"
              href="https://olesmoky.com/collections/drinkware/products/popcorn-sutton-etched-rocks-glass"
              target="_blank"
            >
              <img
                src="https://olesmoky.com/cdn/shop/files/POPCORNSUTTONETCHEDROCKSGLASS_600x600.png?v=1707337513"
                alt="POPCORN SUTTON ETCHED ROCKS GLASS"
              />
            </ImageContainer>
            <SlideText>
              <SlideTitle>POPCORN SUTTON ETCHED ROCKS GLASS</SlideTitle>
              <Button
                as="a"
                href="https://olesmoky.com/collections/drinkware/products/popcorn-sutton-etched-rocks-glass"
                target="_blank"
              >
                Shop
              </Button>
            </SlideText>
          </Slide>

          <Slide>
            <ImageContainer
              as="a"
              href="https://olesmoky.com/collections/headwear/products/popcorn-sutton-script-hat"
              target="_blank"
            >
              <img
                src="https://olesmoky.com/cdn/shop/files/POPCORNSUTTONSCRIPTHAT_600x600.png?v=1707337289"
                alt="POPCORN SUTTON SCRIPT HAT"
              />
            </ImageContainer>
            <SlideText>
              <SlideTitle>POPCORN SUTTON SCRIPT HAT</SlideTitle>
              <Button
                as="a"
                href="https://olesmoky.com/collections/headwear/products/popcorn-sutton-script-hat"
                target="_blank"
              >
                Shop
              </Button>
            </SlideText>
          </Slide>

          <Slide>
            <ImageContainer
              as="a"
              href="https://olesmoky.com/collections/headwear/products/likker-denim-hat"
              target="_blank"
            >
              <img
                src="https://olesmoky.com/cdn/shop/files/LIKKERDENIMHAT_600x600.png?v=1707337330"
                alt="LIKKER DENIM HAT"
              />
            </ImageContainer>
            <SlideText>
              <SlideTitle>LIKKER DENIM HAT</SlideTitle>
              <Button
                as="a"
                href="https://olesmoky.com/collections/headwear/products/likker-denim-hat"
                target="_blank"
              >
                Shop
              </Button>
            </SlideText>
          </Slide>
        </Slider>
      </SliderContainer>
    </Root>
  );
};
