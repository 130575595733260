import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 48px;

  @media (max-width: 1023px) {
    padding: 0 16px;
    padding-bottom: 144px;
  }

  @media (min-width: 1024px) {
    padding: 104px 40px;
    padding-bottom: 144px;
  }
`;

export const SliderContainer = styled.div`
  width: 100%;

  @media (max-width: 1023px) {
    max-width: 343px;
  }

  @media (min-width: 1024px) {
    max-width: 792px;
  }

  @media (min-width: 1440px) {
    max-width: 948px;
  }

  .slick-prev,
  .slick-next {
    background-repeat: no-repeat;
    transform: none;
    z-index: 1;

    @media (max-width: 1023px) {
      top: 120px;
      width: 24px;
      height: 24px;
    }

    @media (min-width: 1024px) {
      top: 180px;
      width: 40px;
      height: 40px;
    }

    &::before,
    &::before {
      content: none;
    }
  }

  .slick-prev {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cpath fill='%23ffffff' d='M26.855 36.667 10.188 20 26.855 3.333l2.958 2.959L16.105 20l13.708 13.708-2.958 2.959Z'/%3E%3C/svg%3E");

    &.slick-disabled {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cpath fill='%23545353' d='M26.855 36.667 10.188 20 26.855 3.333l2.958 2.959L16.105 20l13.708 13.708-2.958 2.959Z'/%3E%3C/svg%3E");
    }

    @media (max-width: 1023px) {
      left: 0;
    }

    @media (min-width: 1024px) {
      left: -76px;
    }
  }

  .slick-next {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cpath fill='%23ffffff' d='M13.146 3.333 29.813 20 13.146 36.667l-2.958-2.959L23.896 20 10.188 6.292l2.958-2.959Z'/%3E%3C/svg%3E");

    &.slick-disabled {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cpath fill='%23545353' d='M13.146 3.333 29.813 20 13.146 36.667l-2.958-2.959L23.896 20 10.188 6.292l2.958-2.959Z'/%3E%3C/svg%3E");
    }

    @media (max-width: 1023px) {
      right: 0;
    }

    @media (min-width: 1024px) {
      right: -76px;
    }
  }
`;

export const Slide = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;

  @media (min-width: 1024px) {
    padding: 0 12px;
  }
`;

export const ImageContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #121212;
  outline: none;

  @media (max-width: 1023px) {
    width: 263px;
    height: 280px;
  }

  @media (min-width: 1024px) {
    width: 372px;
    height: 400px;
  }

  @media (min-width: 1440px) {
    width: 450px;
  }

  img {
    width: 100%;
    object-fit: contain;

    @media (max-width: 1023px) {
      height: 152px;
    }

    @media (min-width: 1024px) {
      height: 272px;
    }
  }
`;

export const SlideText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
`;

export const SlideTitle = styled.div`
  color: #fff;
  font-family: 'Evanston Alehouse 1826 Light';
  font-size: 32px;
  font-weight: 300;
  line-height: normal;
  text-align: center;
`;
