import { forwardRef } from 'react';

import { Root, Container, Input, Mark } from './Checkbox.style';

export const Checkbox = forwardRef(
  ({ className, style, label, ...props }, ref) => {
    return (
      <Root className={className} style={style}>
        <Container>
          <Input ref={ref} type="checkbox" {...props} />
          <Mark>
            <svg
              width={14}
              height={14}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 14 14"
            >
              <path
                fill="currentColor"
                d="m12.341 3.81-7 7a.438.438 0 0 1-.619 0L1.659 7.747a.437.437 0 1 1 .619-.619l2.753 2.754 6.691-6.691a.438.438 0 1 1 .619.619Z"
              />
            </svg>
          </Mark>
        </Container>
        {label}
      </Root>
    );
  }
);

Checkbox.displayName = 'Checkbox';
