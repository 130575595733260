import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
// import instagramSrc from "../assets/vectors/icon-instagram.svg";
import arrowSrc from "../assets/vectors/arrow.svg";

const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const FooterSubscribe = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [emailAddress, setEmailAddress] = useState("");
  const [formStatus, setFormStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (emailAddress == null || emailAddress === "") return;
    if (!emailPattern.test(emailAddress)) {
      setFormStatus("Invalid Email.");
      return;
    }
    if (!executeRecaptcha) {
      setFormStatus("Invalid reCAPTCHA. Please try again.");
      return;
    }
    const captchaToken = await executeRecaptcha("subscribe");

    try {
      setFormStatus("Submitting...");
      const response = await fetch(
        `https://api.olesmoky.com/subscribe-popcornsutton`,
        {
          method: "POST",
          body: JSON.stringify({ emailAddress, captchaToken }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseJson = await response.json();
      console.info({ responseJson });
      setEmailAddress("");
      setFormStatus("Success signing up!");
    } catch (err) {
      console.error({ err });
      const errorJson = err.responseJSON;
      if (
        ["INVALID_CAPTCHA", "INVALID_CAPTCHA_SCORE"].includes(errorJson.type)
      ) {
        setFormStatus("Invalid captcha. Please try again.");
      } else {
        setFormStatus("An error occurred. Please try again.");
      }
    }
  };

  const handleFocus = () => {
    const recaptchaEl = document.getElementsByClassName("grecaptcha-badge");
    if (recaptchaEl?.[0]?.style != null) {
      recaptchaEl[0].style.visibility = "visible";
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ width: "100%" }}>
        <p className="">Sign up to receive our latest news</p>
        {formStatus && (
          <p
            style={{
              color: "#db8f5e",
              position: "absolute",
              bottom: "-40px",
            }}
          >
            {formStatus}
          </p>
        )}
        <input
          type="text"
          name="email"
          value={emailAddress}
          className="email-input"
          placeholder="Enter your email"
          onChange={(e) => setEmailAddress(e.target.value)}
          onFocus={handleFocus}
        />
      </div>

      <button type="submit">
        <img src={arrowSrc} alt="submit" />
      </button>
    </form>
  );
};

export default FooterSubscribe;
