/* eslint-disable no-undef */
/*
let map;

const markerConfigs = [
  {
    label: "The Holler",
    addressLine1: "903 Parkway #128",
    addressLine2: "Gatlinburg, TN 37738",
    lat: 35.71142,
    lng: -83.51986,
  },
  {
    label: "The Barn",
    addressLine1: "131 The Island Dr #9116",
    addressLine2: "Pigeon Forge, TN 37863",
    lat: 35.71142,
    lng: -83.57006,
  },
  {
    label: "The Barrelhouse",
    addressLine1: "650 Parkway",
    addressLine2: "Gatlinburg, TN 37738",
    lat: 35.7124,
    lng: -83.51526,
  },
  {
    label: "6th & Peabody",
    addressLine1: "423 6th Ave S",
    addressLine2: "Nashville, TN 37203",
    lat: 36.15495,
    lng: -86.77711,
  },
];

async function initMap() {
  // eslint-disable-next-line no-undef
  const { Map } = await google.maps.importLibrary("maps");

  map = new Map(document.getElementById("map"), {
    center: { lat: 35.71142, lng: -83.51986 },
    zoom: 7,
    disableDefaultUI: true,
    zoomControl: true,
    styles: [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#212121",
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#212121",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "administrative.country",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "administrative.locality",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#181818",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#1b1b1b",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#2c2c2c",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#8a8a8a",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry",
        stylers: [
          {
            color: "#373737",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#3c3c3c",
          },
        ],
      },
      {
        featureType: "road.highway.controlled_access",
        elementType: "geometry",
        stylers: [
          {
            color: "#4e4e4e",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "transit",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#000000",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#3d3d3d",
          },
        ],
      },
    ],
  });

  // eslint-disable-next-line no-undef
  let infowindow = new google.maps.InfoWindow({
    content: "",
  });

  markerConfigs.map((markerConfig) => {
    // eslint-disable-next-line no-undef
    const marker = new google.maps.Marker({
      position: { lat: markerConfig.lat, lng: markerConfig.lng },
      map,
      title: markerConfig.label,
      data: markerConfig,
    });

    marker.addListener("click", () => {
      infowindow.setContent(
        `<div>
          <h3 style="color: black">${markerConfig.label}</h3>
          ${markerConfig.addressLine1}<br />
          ${markerConfig.addressLine2}
        </div>`
      );
      infowindow.open({
        anchor: marker,
        map,
      });
    });
  });
}

initMap();
*/

// $(document).ready(function () {
//   $(".js--wp-1").waypoint(
//     function () {
//       console.info("fuck me");
//       $(".js--wp-1").addClass("animated fadeIn");
//     },
//     { offset: "60%" }
//   );

//   $(".js--wp-2").waypoint(
//     function () {
//       console.info("fuck me2");
//       $(".js--wp-2").addClass("animated fadeIn");
//     },
//     { offset: "60%" }
//   );
// });

$(window).scroll(function () {
  if ($(window).scrollTop() >= 300) {
    $(".fixed-header").addClass("visible");
  } else {
    $(".fixed-header").removeClass("visible");
  }
});
